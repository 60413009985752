<template>
  <div class="v_com_details g-flex-column">
    <head-back>
      <template v-slot:returnText>
        <span>返回</span>
      </template>

        <template v-slot:default>
        <span>{{titleName}}</span>
      </template>
    </head-back>

    <div class="v-com-details-containers">
      <van-list class="v-com-details-list" v-show="detailList.length" v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad" :immediate-check="false">
        <div class="v-com-details-item g-flex-align-center" v-for="(item, index) in detailList" :key="index" @click="detailsItemClick(item)">
          <div class="v-com-details-item-left">
            <p class="v-com-details-item-left-money">{{Number(item.money) > 0 ? '+' + item.money : item.money  }}</p>
            <p class="v-com-details-item-left-title">{{item.title}}</p>
          </div>
          <div class="v-com-details-item-right">{{formatDate(item.create_time, 'yyyy-MM-dd hh:mm:ss')}}</div>
        </div>
      </van-list>
      
      <!-- 没有列表 -->
      <NoList ref="NoList" v-show="!detailList.length"/>

    </div>

    <com-details-pop ref="ComDetailsPop"/>

  </div>
</template>

<script>
  import HeadBack from '../../components/HeadBack.vue'
  import { PAGE, LIMIT } from '@/utils/constants.js'
  import { formatDate } from '../../utils/index.js'
  import ComDetailsPop from './ComDetailsPop.vue'
  import { apiMoneyDetailList } from '@/utils/api.js'
  export default {
    components: { HeadBack, ComDetailsPop },
    data() {
      return {
        routeType: this.$route.params.type,
        loading: false,
        finished: false,
        form: {
          page: PAGE,
          limit: LIMIT + 5,
          type: this.$route.params.type
        },
        detailList: []
      }
    },
    created() {
      this.apiMoneyDetailListHandel()
    },
    methods: {
      formatDate,
      onLoad() {
        this.form.page++
        this.apiMoneyDetailListHandel()
      },
      // 获取资金明细
      async apiMoneyDetailListHandel() {
        const { success, data } = await apiMoneyDetailList(this.form)
        if(!success) return
        this.detailList = this.detailList.concat(data.list) 
        this.loading = false
        this.finished = data.list.length < this.form.limit
        console.log(data)
      },
      
      // 每一项点击
      detailsItemClick(item) {
        console.log('sdasdas')
        this.$refs.ComDetailsPop.onShow(true, this.routeType, item)
      }
    },
    computed: {
      titleName() {
        if(this.routeType === '0') return '任务本金明细'
        if(this.routeType === '1') return '任务佣金明细'
        if(this.routeType === '2') return '推广佣金明细'
        return '资金总明细'
      }
    }
  }
</script>

<style lang="scss">
.v_com_details {
  height: 100%;
  background: #F1F1F1;
  .v-com-details-containers {
    flex: 1;
    overflow: auto;
    padding-top: 10px;
    .v-com-details-list {
      background: $white;
      padding-left: 18px;
      height: auto;
      .v-com-details-item {
        padding: 14px 20px 14px 0;
        border-bottom: 1px solid #DCDCDC;
        .v-com-details-item-left {
          letter-spacing: 1px;
          flex: 1;
          .v-com-details-item-left-money {
            font-size: 16px;
            color: $main_color;
          }
          .v-com-details-item-left-title {
            padding-top: 10px;
            font-size: 12px;
            color: #2E2E2E;
          }
        }

        .v-com-details-item-right {
          font-size: 12px;
          color: #999999;
        }
      }
    }
  }
}
</style>