<template>
  <div class="s_com_details_pop">
    <van-popup v-model="show" :safe-area-inset-bottom="true" position="right" :style="{width: '100%', height: '100%' }" class="s-com-details-container">
      <head-back :isRouterBack="false" @emitHeadBack="emitHeadBack">
        <template v-slot:returnText>
          <span>返回</span>
        </template>

         <template v-slot:default>
          <span>{{titleName}}</span>
        </template>
      </head-back>

      <div class="s-com-details-content g-flex-column g-flex-align-center">
        <div class="s-com-details-icon s-com-details-icon-one g-flex-align-center g-flex-justify-center">
          <i class="iconfont icon-80"></i>
        </div>

        <div class="s-meeage-detail-title" v-show="form.category === 1 && form.type == 4">返本金</div>
        
        <div class="s-meeage-detail-title" v-show="form.category === 2  && form.type == 4">返佣金</div>
        <div class="s-meeage-detail-title" v-show="form.category === 2  && form.type == 5">推广返佣</div>

        <div class="s-meeage-detail-title" v-show="form.category === 1  && form.type == 2">本金提现</div>
        <div class="s-meeage-detail-title" v-show="form.category === 2  && form.type == 2">佣金提现</div>


        <p class="s-com-details-money">{{Number(form.money) > 0 ? '+' + form.money : form.money  }}</p>

        <span class="s-com-details-status">交易成功</span>

        <ul class="s-com-details-card">
          <li class="s-com-details-card-item g-flex-align-center">
            <span class="s-com-details-card-item-title">交易金额:</span>
            <div class="s-com-details-card-item-text">{{form.money}}元</div>
          </li>
          <li class="s-com-details-card-item g-flex-align-center">
            <span class="s-com-details-card-item-title">交易方式:</span>
            <div class="s-com-details-card-item-text" v-show="form.category === 1 && form.type == 4">返本金</div>
            
            <div class="s-com-details-card-item-text" v-show="form.category === 2  && form.type == 4">返佣金</div>
            <div class="s-com-details-card-item-text" v-show="form.category === 2  && form.type == 5">推广返佣</div>

            <div class="s-com-details-card-item-text" v-show="form.category === 1  && form.type == 2">本金提现</div>
            <div class="s-com-details-card-item-text" v-show="form.category === 2  && form.type == 2">佣金提现</div>
          </li>
          <li class="s-com-details-card-item g-flex-align-center">
            <span class="s-com-details-card-item-title">创建时间:</span>
            <div class="s-com-details-card-item-text">{{formatDate(form.create_time, 'yyyy-MM-dd hh:mm:ss')}}</div>
          </li>
          <li class="s-com-details-card-item g-flex-align-center">
            <span class="s-com-details-card-item-title">订单号:</span>
            <div class="s-com-details-card-item-text">{{form.id}}</div>
          </li>
        </ul>
      </div>
    </van-popup>
  </div>
</template>

<script>
  import HeadBack from '../../components/HeadBack.vue'
  import { formatDate } from '../../utils/index.js'
  export default {
    components: { HeadBack },
    data() {
      return {
        show: false,
        titleName: '', 
        form: {
          balance: "95560.06",
          category: 1,
          create_time: 1608365541,
          id: 569,
          key_id: 56,
          money: "50.00",
          title: "本金",
          type: '',
          user_id: 185642,
        }
      }
    },
    methods: {
      formatDate,
      onShow(flag, type, obj = {}) {
        console.log('type', type)
        console.log(obj)
        if(type == 0) this.titleName = '任务本金详情'
        else if(type == 1)  this.titleName = '任务佣金详情'
        else if(type == 2) this.titleName = '推广佣金详情'
        else this.titleName =  '资金明细详情'
        this.form = obj
        this.show = flag
      },

      emitHeadBack() {
        this.show = false
      }
    }
  }
</script>

<style lang="scss">
.s_com_details_pop {
  .van-overlay {
    background-color: #ffffff;;
  }
  .s-com-details-container {
    background: #F1F1F1;
    .s-com-details-content {
      padding: 0 12px;
      .s-com-details-icon {
        margin-top: 40px;
        width: 42px;
        height: 42px;
        border-radius: 50%;
        .iconfont {
          color: $white;
          font-size: 26px;
        }
        &.s-com-details-icon-one {
          background: #1989FA;
        }
      }

      .s-meeage-detail-title {
        padding-top: 10px;
        font-size: 16px;
        color: #000000;
      }

      .s-com-details-money {
        color: #363636;
        font-size: 26px;
        padding-top: 16px;
      }

      .s-com-details-status {
        padding-top: 14px;
        font-size: 14px;
      }

      .s-com-details-card {
        margin-top: 30px;
        align-self: stretch;
        background: $white;
        border-radius: 8px;
        padding: 20px 16px;
        .s-com-details-card-item {
          padding-top: 22px;
          color: #797979;
          font-size: 14px;
          line-height: 18px;
          .s-com-details-card-item-title {
            flex: 1;
          }
          .s-com-details-card-item-text {
            padding-left: 10px;
            color: #151515;
            flex: 4;
            text-align: left;
            word-break: break-all;
            letter-spacing: 1px;
          }
          &:nth-of-type(1) {
            padding-top: 0;
          }
        }
      }
    }
  } 
}
</style>